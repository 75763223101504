import Link from 'next/link'
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'

export default function Dropdown({popoverItem}) {
    
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    return (
        <Popover className={"inline-flex"}>
            <Popover.Group as="nav" className="hidden md:flex space-x-10">
                <Popover className="relative">
                    {({ open }) => (
                        <>
                            <Popover.Button class={`text-base font-medium text-white hover:text-color-primary ${open ? 'text-color-primary' : ''} group inline-flex items-center text-base font-medium focus:outline-none `} aria-expanded="false">
                                <span>{popoverItem?.name}</span>
                                <ChevronDownIcon
                                className={classNames(
                                    open ? 'text-color-primary' : 'text-white',
                                    'ml-2 h-5 w-5 group-hover:text-color-primary'
                                )}
                                aria-hidden="true"
                                />
                            </Popover.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                            >
                                <Popover.Panel class="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0">
                                    <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">

                                        <div class="relative grid  bg-white ">
                                            {popoverItem?.list.map((item) => (
                                                <Link href={item?.href}>
                                                <a
                                                key={item?.name}
                                                
                                                className="-m-3 px-5 py-4 link-dropdown   block rounded-md hover:bg-gray-50 transition ease-in-out duration-150 dropdownitem">
                                                    <div className="ml-4">
                                                        <p className="text-base font-medium text-gray-900 hover:text-color-primary mb-0">{item?.name}</p>
                                                        {item?.description ? (
                                                            <p className="mt-0 text-sm text-gray-500 hover:text-color-primary">{item?.description}</p>
                                                            ) : ('')
                                                        }
                                                    </div>
                                                </a>

                                                </Link>

                                            ))}
                                            
                                        </div>
                                    </div>
                                </Popover.Panel>
                            </Transition>
                        </>
                    )}
                </Popover>
            </Popover.Group>
        </Popover>
    )
}