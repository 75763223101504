import React,  { useState, useEffect} from 'react';
import '../public/js/loader.js'
import LogoSVG from '../public/imgs/logo-alphalives-new.svg';
import Home from '../public/imgs/home.svg'
import Dropdown from './dropdown'
import Link from 'next/link'


export default function HeaderMenu({breadcrumbs=undefined, isIndex=undefined})  {
  const [active, setActive] = useState(true);
  useEffect(() => {
    if(typeof(window) !== "undefined"){
      $ = window.jQuery
      var midheight = $(window).height()/2;
      $(".logoBlackLetter").attr("fill", "#fff"); // logo
      $(window).on('scroll',function()
      {
        if (window.matchMedia("(min-width: 992px)").matches) {
          if($(window).scrollTop() < $('#big-zone')?.offset()?.top - 20){
            //$(".logoBlackLetter").attr("fill", "#fff"); // logo
            //$(".aLogoOrange").attr("fill", "#ff7300"); // logo
            $(".bar").removeClass("swap");
            $(".breadcrumb").removeClass("black");
            if($(".homeIcon")){
              $(".homeIcon").removeClass("black")
              $(".homeIcon").removeClass("white")
            }
          }
          if($(window).scrollTop() < $('#big-zone')?.offset()?.top - 20){
            $("#header-lg").removeClass('header-bg-gray');
          }
          if($(window).scrollTop() >= $('#big-zone')?.offset()?.top - 20){
            $("#header-lg").addClass('header-bg-gray');
          }
          if($(window).scrollTop() >= $('#big-zone')?.offset()?.top -20){
            //$(".logoBlackLetter").attr("fill", "#333"); // logo
            //$(".aLogoOrange").attr("fill", "#ff7300"); // logo
            $("#orange").removeClass("white"); //logo
            $("#grey").removeClass("white"); //logo
            $(".bar").addClass("swap");
            $(".breadcrumb").addClass("black");
            $(".breadcrumb").removeClass("orange");
            if($(".homeIcon")){
              $(".homeIcon").addClass("black")
              $(".homeIcon").removeClass("white")
            }
          }
          $('.imagedBg').each(function() {
            var top = $(this).offset().top;
            var height = $(this).height();
            if($(window).scrollTop() >= top -20 && $(window).scrollTop() < (top + height)-20){
              //$(".logoBlackLetter").attr("fill", "#fff"); // logo
              //$(".aLogoOrange").attr("fill", "#ff7300"); // logo
              $(".bar").removeClass("swap");
              $(".breadcrumb").removeClass("black");
              if($(".homeIcon")){
                $(".homeIcon").removeClass("black")
                $(".homeIcon").removeClass("white")
              }
            }
          })
          $('.bg-color-orange').each(function() {
            var top = $(this).offset().top;
            var height = $(this).height();
            if($(window).scrollTop() >= top -20 && $(window).scrollTop() < (top + height)-20){
              //$(".logoBlackLetter").attr("fill", "#fff"); // logo
              //$(".aLogoOrange").attr("fill", "#fff"); // logo#ff7300
              $("#orange").addClass("white"); //logo
              $("#grey").addClass("white"); //logo
              $(".bar").removeClass("swap");
              $(".breadcrumb").removeClass("black");
              $(".contact-link").addClass("black");
              if($(".homeIcon")){
                $(".homeIcon").removeClass("black")
                $(".homeIcon").addClass("white")
              }
            }
          })
          $('.bg-white, .bg-gray').each(function() {
            var top = $(this)?.offset()?.top;
            var height = $(this).height();
            if($(window).scrollTop() >= top-20 && $(window).scrollTop() < (top + height)-20){
              //$(".logoBlackLetter").attr("fill", "#333"); // logo
              //$(".aLogoOrange").attr("fill", "#ff7300"); // logo#ff7300
              $("#orange").removeClass("white"); //logo
              $("#grey").removeClass("white"); //logo
              $(".bar").addClass("swap");
              $(".breadcrumb").addClass("black");
              $(".breadcrumb").removeClass("orange");
              $(".contact-link").removeClass("black");
              if($(".homeIcon")){
                $(".homeIcon").addClass("black")
                $(".homeIcon").removeClass("white")
              }
            }
          })
        
        }
      })
    }
  })
  const handleClick = () => {
    setActive(!active);
  };
  const savoir = {
    name: "Notre expertise",
    list: [
      {
        name: "Création de sites Internet",
        href: "/creation-site-internet/",
        description : "Notre agence est spécialisée dans la réalisation de site Internet",
      },
      {
        name: "Le développement sur mesure",
        href: "/le-developpement-sur-mesure",
        description : "L'agence développe des outils métier sur mesure pour des PME et grands comptes ",
      },
      {
        name: "Applications mobiles et PWA",
        href: "/agence-application-mobile",
        description : "L'agence est spécialisé dans la création d'application mobile iOs et Androïd",

      },
    ] 
  }
  return (
    <>
      {/* <ol className="breadcrumb hidden lg:block" itemScope="" itemType="https://schema.org/BreadcrumbList">
        
        {breadcrumbs?.map((bread, index) => (
            <li className="breadcrumb-item" key={index} itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem">
              <a itemProp="item" href={bread?.link} alt={bread?.alt}>
              <span itemProp="name">{bread?.text}</span> </a>
              <meta itemProp="position" content={index+1} />
            </li>
        ))}

      </ol> */}
      <div className={` w-full header z-20 ${ active ? '' : 'openedMenu' } lg:hidden`} id="headerSection" style={isIndex ? {} : {opacity: 1}}>
        <div className="items-center justify-between">
          <a href="/" className="absolute top-0" title='Agence web alphalives'>
            <LogoSVG className="logoLight" id="logo" alt="logo Alphalives"/>
          </a>
          <a href="/contact">
            <div className="contact-link">Nous contacter<div className="inner"></div>
          </div></a>
          {!isIndex ?
          <>
            <Link href="/">
              <a  className="homeHolder" title='Accueil'>
                <Home className="homeIcon" alt="logo Accueil"></Home>
              </a>
            </Link>
          </>
          :
          <>
            <div onClick={handleClick} className={`${ active ? 'menu-collapsed' : 'menu-expanded' }  `}>
              <div className="bar button" ></div> {/* ref={(element) => { this.bar = element; }} */}
              <nav className="grid gap-4 grid-cols-12">
                <div className="col-span-12 md:col-start-6 md:col-span-7 lg:col-span-5 lg:col-start-7 p-0">
                  <ul>
                    
                  <li>
                      <a href="/agence-web-paris" title="agence web à paris">
                              <div className="catg">Une agence web à Paris</div>
                              <div className="effect hidden-sm-down"></div>
                      </a>
                    </li>

                    <li>
                      <a href="/digitalisation/">
                              <div className="catg">Notre métier, vous digitaliser</div>
                              <div className="effect hidden-sm-down"></div>
                      </a>
                    </li>
                    
                  
                    </ul>
                    <ul>
                    <li>
                      <a href="/creation-site-internet/">
                              <div className="catg">Création de sites Internet</div>
                              <div className="effect hidden-sm-down"></div>
                      </a>
                    </li>
                    <li>
                      <a href="/le-developpement-sur-mesure">

                              <div className="catg">Le développement sur mesure</div>
                              <div className="effect hidden-sm-down"></div>
                      </a>
                    </li>

                    <li>
                      <a href="/agence-application-mobile" alt="agence application mobile">

                              <div className="catg">Applications mobiles et PWA</div>
                              <div className="effect hidden-sm-down"></div>
                      </a>
                    </li>


                    <li>
                      <a href="/references">
                              <div className="catg">Nos références</div>
                              <div className="effect hidden-sm-down"></div>
                      </a>
                    </li>
                    <li>
                      <a href="/contact">
                              <div className="catg">Contact</div>
                              <div className="effect hidden-sm-down"></div>
                      </a>
                    </li>
        
                  </ul>
                </div>
                <div className="col-span-12 social-menu hidden-sm-down grid grid-cols-2 hidden md:grid">
                  <div className="col-md-6 col-xs-12 no-padding address">
                    <img src="/imgs/logo-alphalives.svg" className=" h-8" alt="logo alphalives" />
                    <span>
                      <i className="fa fa-map-marker" aria-hidden="true"></i> 5 rue Ponscarme 75013 Paris
                    </span>
                    <span>
                      <i className="fa fa-phone" aria-hidden="true"></i> 01 47 70 19 74
                    </span>
                  </div>
                  {/* <div className="col-md-6 col-xs-12 no-padding socials"> 
                    <a target="_blank" href="https://twitter.com/alphalives" rel="noreferrer noopener">
                      <FontAwesomeIcon className="icon-fontawesome" icon={["fab", "twitter"]} />
                    </a>
                    <a target="_blank" href="https://www.linkedin.com/company/alphalives-multim-dia" rel="noreferrer noopener">
                      <FontAwesomeIcon className="icon-fontawesome" icon={["fab", "linkedin-in"]} />
                    </a> 
          </div>*/ }
                </div>
              </nav>
            </div>
          </>
          }
        </div>
      </div>
      <header className="header-bg-gray hidden lg:block fixed top-0 w-full z-20 " id="header-lg">
        <nav className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-12 " aria-label="Top">

          <div className="w-full  flex items-center justify-start border-b border-indigo-500  lg:border-none md:justify-start md:space-x-10">
            <div className="flex items-center w-0 flex-1">
              <a href="/" className="" title='Agence web alphalives'>
                  <span className="sr-only">Alphalives</span>
                  <LogoSVG className="logoLight" id="logo" alt="logo Alphalives" style={{position:'unset'}}/>
              </a>
            </div>
            <div className="hidden ml-10 space-x-8 lg:block">

              <Link href='/agence-web-paris'>
              <a title='agence web paris' className="text-base font-medium text-white hover:text-color-primary"> L'agence </a>
              </Link>

              <Dropdown popoverItem={savoir}></Dropdown>

              <Link href='/references'>
              <a title="reference de l'agence" className="text-base font-medium text-white hover:text-color-primary dropdownitem"> Nos références </a>
              </Link>
              <Link href='/contact'>
              <a title="contactez l'agence" className="text-base font-medium text-white hover:text-color-primary dropdownitem"> Contact </a>
              </Link>

            </div>
          </div>
        </nav>
      </header>
    </>
  )
}
